import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Navbar from '../Shared/Navbar';
import ContactMe from './ContactMe/ContactMe';
import HeroSection from './HeroSection/HeroSection';
import './Home.css'
import Projects from './Projects/Projects';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div  >

            <div className='heroBg'>
                <div className='sm:myContainer'>
                    {/* Navbar section */}
                    <Navbar></Navbar>

                    {/* Hero Section */}
                    <div  >
                        <HeroSection></HeroSection>
                    </div>
                </div>
            </div>

            {/* Projects Section  */}
            <div id="projects" className='projects h-fit mb-20  '>
                <Projects></Projects>
            </div>
            {/* Contact Me */}
            <div className='flex justify-center flex-col items-center mb-[10%] ' >
                <p className='text-lg'>Do you need a Developer?</p>
                <Link to="/contact" className='text-5xl font-bold flex justify-center items-center justify-items-center gap-5 cursor-pointer letsTalkText tracking-wider'>Let's Talk <span className='letsTalkArrow text-3xl font-bold '> <FontAwesomeIcon icon={faArrowRightLong} /> </span> </Link>
            </div>
        </div>
    );
};

export default Home;