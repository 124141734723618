import React from 'react';
import Navbar from '../Shared/Navbar';

const Blogs = () => {
    return (
        <div>
            <Navbar />
            <div className='flex justify-center items-center h[100vh] '>
                <h1 className='text-4xl mt-[10%] '>Comming Soon....</h1>
            </div>
        </div>
    );
};

export default Blogs;