import React from 'react';
import './HeroSection.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import './HeroSection.css'
import Particle from './Particle';
import heroImg from '../../../images/heroImg.jpg';
const HeroSection = () => {

    return (
        <>
            {/* <Particle></Particle> */}
            <div className='sm:h-[96vh] h-fit xs:pb-10 '  >
                < div className='md:mx-[15%] xs:mx-[2%] relative flex justify-center text-left xs:text-center xs:h-[70vh]'>
                    <div className='grid  grid-cols-1 justify-center items-center'>
                        <div className='xs:hidden [z-index:-1] absolute top-0 left-0 [margin-top:-100px] '>
                            <h1 className='text-[600px] [opacity:.1]  '>Hello</h1>
                        </div>
                        <div className='md:w-1/2 [z-index:2] absolute top-0 md:left-[50%] left-0  md:[transform:translateX(-50%)] '>
                            <h1 className='sm:text-[40px] text-[45px] w-full xs:text-left  md:[margin-top:180px] xs:mt-20 text-black leading-normal '><span className='text-6xl'></span>
                                <p className='xs:hidden'>I'm <b>Jahid Hassan.</b><br />
                                    A passionate web developer who is continuously exploring new technologies.</p>
                                <p className='sm:hidden'>I'm  <b>Jahid .</b><br />
                                    A passionate web developer who is continuously exploring new technologies.</p>

                            </h1>
                            <div>
                                <a href='https://drive.google.com/file/d/1w2YiVt48ioA4x2w3XC02i-TtGkw9EbYZ/view?usp=sharing' className='shadow-xl' target={'_blank'}>
                                    <button id='downloadResume' className='mt-20 bg-black text-white text-xl py-3 px-4 rounded-full overflow-hidden '>
                                        Download Resume <FontAwesomeIcon id='downloadArrow' icon={faArrowDown} />
                                    </button>
                                </a>
                            </div>
                        </div>

                    </div>

                </div>
            </div >
        </>
    );
};

export default HeroSection;