import logo from './logo.svg';
import './App.css';
import Home from './pages/Home/Home';
import Navbar from './pages/Shared/Navbar';
import { Route, Routes } from 'react-router-dom';
import ContactMe from './pages/Home/ContactMe/ContactMe';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProjectDetail from './pages/Home/Projects/ProjectDetail';
import Loading from './pages/Shared/Loading';
import Blogs from './pages/Blogs/Blogs';
import About from './pages/About/About';

function App() {
  return (
    <div >

      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/home' element={<Home />}></Route>
        <Route path='/contact' element={<ContactMe />}></Route>
        <Route path='/blogs' element={<Blogs />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='/projectDetail/:id' element={<ProjectDetail />}></Route>
      </Routes>
      <ToastContainer
        autoClose={1000}
      />
    </div>
  );
}

export default App;
