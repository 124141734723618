import { faBars, faClose, faCross } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import ContactMe from '../Home/ContactMe/ContactMe';
import './Navbar.css'

const Navbar = () => {
    const [barIcon, setbarIcon] = useState(true)
    const [bgColor, setBgColor] = useState('');
    const [txtColor, setTxtColor] = useState('');
    const [titleBold, setTitleBold] = useState('');

    useEffect(() => {
        if ((window.location.href === "https://jahid-hassan.com/contact") || (window.location.href === "https://jahid-hassan.com/blogs") || (window.location.href === "https://jahid-hassan.com/about")) {
            setBgColor('bg-zinc-900');
            setTxtColor('text-white');
            setTitleBold('font');
        }
        else {
            setBgColor('');
            setTxtColor('text-black');
            setTitleBold('font-bold');
        }
    }, [window.location.href])


    return (
        <div >
            <nav className={`${bgColor}`} >
                {/* myContainer is defined in app.css */}
                <div className=' md:mx-[15%] xs:mx-[2%]  flex flex-row items-center   sm:gap-y-0 gap-y-5 justify-between py-5 '>
                    <div>
                        <Link to='/' className={`text-3xl ${titleBold} ${txtColor}`}>Jahid Hassan</Link>
                    </div>
                    <div className="sm:hidden block">
                        {
                            barIcon
                                ?
                                <div className={`child:${txtColor}`}>
                                    <FontAwesomeIcon onClick={() => setbarIcon(!barIcon)} className={`mobileNavOptionBar ${txtColor}  text-3xl`} icon={faBars} />
                                </div>
                                :
                                <div className={`child:${txtColor}`}>
                                    <FontAwesomeIcon onClick={() => setbarIcon(!barIcon)} className={`${txtColor} text-3xl`} icon={faClose} />
                                </div>
                        }
                    </div>
                    <div className='xs:hidden'>
                        <ul className={`flex flex-col gap-y-2 sm:flex-row sm:gap-y-0 sm:gap-x-5 child:text-xl  child:cursor-pointer ${txtColor}`}>
                            <HashLink to="/home#projects"><li>My work</li></HashLink>
                            <Link to="/blogs"><li >Blogs</li></Link>
                            <Link to="/about"><li >About me</li></Link>

                            <Link to='/contact' >Hire me</Link>
                        </ul>
                    </div>
                </div>
                <div hidden={barIcon} className='sm:hidden mobileOptionBar  bg-gray-600 w-full text-center'>
                    <ul className={`flex flex-col gap-y-0   sm:flex-row sm:gap-y-0 sm:gap-x-5 child:text-xl  `}>
                        <Link to="/home#projects"><li className='hover:bg-black py-4 text-white'>My work</li></Link>
                        <Link to="/about"><li className='hover:bg-black py-4 text-white'>About me</li></Link>
                        <Link to="/blogs"><li className='hover:bg-black py-4 text-white'>Blogs</li></Link>
                        <Link to='/contact' ><li className='hover:bg-black py-4 text-white'>Hire me</li></Link>
                    </ul>
                </div>
            </nav >
        </div >
    );
};

export default Navbar;