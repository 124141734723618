import React, { useEffect, useInsertionEffect, useState } from 'react';
import './Projects.css';
import nissan from '../../../images/nissanParts.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import Loading from './../../Shared/Loading';
import { SERVER_URL } from '../../Shared/variables';

const Projects = () => {
    const navigate = useNavigate()
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    window.addEventListener('scroll', reveal);
    function reveal() {

        const reveals = document.querySelectorAll('.project');

        for (var i = 0; i < reveals.length; i++) {

            const windowheight = window.innerHeight;
            const revealtop = reveals[i].getBoundingClientRect().top;
            const revealpoint = 150;

            if (revealtop < windowheight - revealpoint) {
                reveals[i].classList?.add('active');
                reveals[i].classList?.remove('invisible');
            }

            else {
                reveals[i].classList?.remove('active');
                reveals[i].classList?.add('invisible');
            }
        }
    }
    useEffect(() => {
        setLoading(true)
        fetch(`${SERVER_URL}/projects`)
            .then(res => res.json())
            .then(data => {

                setProjects(data);
                setLoading(false)
            })
    }, []);

    // Sort the list by order
    projects.sort((a, b) => (a.order > b.order) ? 1 : -1)

    return (
        <div className='md:mx-[15%] xs:mx-[2%] [zIndex:2]'>
            <div className='pt-20'>
                <p className='xs:text-center xs:bg-zinc-200 xs:py-[5%]'>
                    <small className='text-xl'>Explore My</small><br />
                    <span className='text-4xl font-bold'>Latest Projects</span>
                </p>
            </div>

            <div className='mt-[5%] '>
                {/* Card One  */}
                {
                    projects?.map((p, index) => <div key={p.order} className={` [order:${p.order}] flex flex-col project project items-center relative `}>

                        <div>
                            <p id="projectType" className='projectType  [z-index:0] absolute top-20 md:left-[22%]  left-0 text-3xl font-bold'>{p.type}</p>
                        </div>
                        <div className='[z-index:99]  projectBottom relative sm:w-7/12 h-fit w-full   my-20 sm:p-10 xs:p-5 font-bold overflow-hidden shadow-xl bg-white'>

                            <div className=''>
                                {/* Side bar  */}

                                <div className='xs:hidden   sidebar absolute top-0 right-0 h-full w-2/6  bg-zinc-300 flex items-center justify-center '>
                                    <h1 className='text-3xl text-[100px] '>{index < 10 ? "0" + (index + 1) : index + 1}</h1>
                                </div>

                                <div>
                                    <h1 className='text-5xl xs:text-4xl'>{p.name}</h1>
                                    <p className='mt-5 '>
                                        <span className='text-xl'>Short Description:</span>
                                        <span className='[font-weight:500]'>{p.description}</span>
                                    </p>
                                    <div className=' child:px-2 child:py-2 child:border-2 child:border-black sm:child:mx-1 child:rounded-full my-10 sm:flex '>

                                        <a className=' xs:w-full xs:block xs:text-center xs:text-xl' href={p.liveSite} target={"_blank"}>
                                            <button className=''>Live Site &nbsp; <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> </button>
                                        </a>
                                        <br />
                                        <a className='xs:w-full xs:block xs:text-center xs:text-xl' href={p.clientCode} target={"_blank"}>
                                            <button className=''>Client Code</button>
                                        </a>
                                        <br />
                                        {p.serverCode && <a className='xs:w-full xs:block xs:text-center xs:text-xl' href={p.serverCode} target={"_blank"}>
                                            <button >Server Code</button>
                                        </a>}
                                    </div>
                                </div>
                                <Link to={`/projectDetail/${p._id}`} className='detailsBtn  absolute bottom-1 md:bottom-2 md:w-2/4 xs:block  xs:text-xl w-full' >
                                    <button className='border-2 rounded-full w-[90%]   py-2 border-black'>Details &nbsp; <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> </button>
                                </Link>
                            </div>
                        </div>
                    </div>)
                }

                {/* Second Card  */}

            </div>
        </div>
    );
};

export default Projects;