import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from './../../Shared/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import Loading from '../../Shared/Loading';
import { SERVER_URL } from '../../Shared/variables';

const ProjectDetail = () => {
    const [projects, setProjects] = useState({});
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        setLoading(true)
        fetch(`${SERVER_URL}/projects/${id}`)
            .then(res => res.json())
            .then(data => {
                setLoading(false)
                setProjects(data)
            })

    }, []);
    if (loading) {
        return <Loading></Loading>
    }


    return (
        <div className='mb-10'>
            <Navbar />
            <div div className='md:mx-[15%] '>
                <h1 className='text-4xl my-20 font-bold'>Project Name: {projects[0]?.name}</h1>
                <div class="carousel ">
                    <div id="slide1" class="carousel-item relative w-full ">
                        <img src={projects[0]?.img[0]} class="w-full" />
                        <div class="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                            <a href="#slide3" class="btn btn-circle">❮</a>
                            <a href="#slide2" class="btn btn-circle">❯</a>
                        </div>
                    </div>
                    <div id="slide2" class="carousel-item relative w-full ">
                        <img src={projects[0]?.img[1]} class="w-full" />
                        <div class="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                            <a href="#slide1" class="btn btn-circle">❮</a>
                            <a href="#slide3" class="btn btn-circle">❯</a>
                        </div>
                    </div>
                    <div id="slide3" class="carousel-item relative w-full ">
                        <img src={projects[0]?.img[2]} class="w-full" />
                        <div class="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                            <a href="#slide2" class="btn btn-circle">❮</a>
                            <a href="#slide1" class="btn btn-circle">❯</a>
                        </div>
                    </div>
                </div>
                {/* Carousel Section End  */}

                <div className='flex flex-row mt-10'>
                    <div><a href={projects[0]?.liveSite} className="btn btn-primary border-2 border-black rounded-full mx-2">Live Site &nbsp; <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a></div>
                    <div><a href={projects[0]?.clientCode} className="btn btn-primary border-2 border-black rounded-full mx-2">Client Code</a></div>

                    {
                        projects[0]?.serverCode &&
                        <div><a href={projects[0]?.serverCode} className="btn btn-primary border-2 border-black rounded-full mx-2">Server Code</a></div>
                    }
                </div>


                <div className='mt-10'>
                    <p className='text-xl mb-10'>
                        <span className='font-bold text-2xl'>Short Description: <br /></span>
                        <p className='ml-[2%]'>{projects[0]?.description}</p>
                    </p>
                    <p>
                        <span className='text-bold text-2xl font-bold'>Key Features:</span>
                        <span>
                            <ul className='text-xl ml-[2%]'>
                                {
                                    projects[0]?.keyFeatures.map((f, index) => <li key={index}> -{f}</li>


                                    )}
                            </ul>
                        </span>
                    </p>
                    <p className='mt-10'>
                        <span className='text-bold text-2xl  font-bold'>Technology Used:</span>
                        <span>
                            <ul className='text-xl ml-[2%]'>
                                {
                                    projects[0]?.technologies.map((f, index) => <li key={index}> -{f}</li>


                                    )}
                            </ul>
                        </span>
                    </p>
                </div>

            </div>
        </div >
    );
};

export default ProjectDetail;