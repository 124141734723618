import React from 'react';
import { ReactLoading } from 'react-loading';
import './Loading.css'

const Loading = () => {
    return (
        <div className='w-full h-[100vh] bg-black '>
            <div className='flex  flex-col sjustify-center items-center justify-items-center h-[100vh] pt-[20%] '>
                <h1 className='loadingDot text-3xl text-white bg-white p-2 border-2 border-white rounded-full '>aa</h1>
                <br />
                <h1 className='text-5xl font-bold text-white'>Wait a bit..</h1>
            </div>
        </div>
    );
};

export default Loading;