import React from 'react';
import Navbar from '../Shared/Navbar';

const About = () => {
    return (
        <div>
            <Navbar />
            <div className='md:mx-[15%] mt-20  '>
                <h1 className='text-3xl '>I'm Jahid Hassan, an HSC graduate and passionate learner who is always ready to explore new technologies and stay updated. I want to see myself in a renowned tech industry-leading team and become established soon.</h1>

            </div>
        </div>
    );
};

export default About;