import { faFacebook, faGithub, faInstagram, faLinkedin, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faCircle, faCircleMinus, faEnvelope, faMailBulk, faMailForward, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Navbar from '../../Shared/Navbar';
import 'tippy.js/dist/tippy.css';
import './ContactMe.css'
import { useForm } from 'react-hook-form';
import axios from 'axios';
import ReactLoading from 'react-loading';
import { SERVER_URL } from '../../Shared/variables';


const ContactMe = () => {
    const [loadingEmail, setLoadingEmail] = useState(false);

    function copyDivToClipboard() {
        const range = document.createRange();
        range.selectNode(document.getElementById("emailText"));
        window.getSelection().removeAllRanges(); // clear current selection
        window.getSelection().addRange(range); // to select text
        document.execCommand("copy");
        window.getSelection().removeAllRanges();// to deselect
        toast.success('Email copied to clipboard')
    }

    const { register, handleSubmit, reset } = useForm();
    const onSubmit = async data => {
        setLoadingEmail(true)
        console.log("....");
        const body = {
            toEmail: 'contact@itemsideas.com',
            subject: 'About contact us',
            text: data.message,
            sendFromEmail: process.env.sendFromEmail,
            passSendFrom: process.env.passSendFrom,

        }
        try {
            const response = await axios.post(`${SERVER_URL}/email`, body)
            console.log(response);
            if (response.data.success === true) {
                setLoadingEmail(false)
                toast.success('Email Sent Successfully');
                reset({ name: '', email: '', message: '' });
            }

            else {
                setLoadingEmail(false)
                toast.error(" The email didn't send. Try again.");

            }
        }
        catch (error) {
            setLoadingEmail(false)
            toast.error(error)
        }

    };

    return (
        <div >
            <Navbar />
            <div className='md:mx-[15%] xs:mx-[2%] flex justify-center mt-[7%]'>
                <div className='h-[60vh] py-10 w-full my-5 '>
                    <div className='grid md:grid-cols-[1fr,2fr] grid-cols-1 xs:gap-10  justify-center '>
                        <div className='w-full '>
                            <h1 className='text-5xl font-bold leading-relaxed'>Get in touch- Let's Work Together </h1>
                            <div>
                                {/* <p className='text-md mt-[10%]'> <FontAwesomeIcon icon={faPhone} /> &nbsp;  </p> */}
                                <div className='emailTextDiv relative'>
                                    <p id='emailText' onClick={copyDivToClipboard} className='text-md mt-[5%]'>
                                        <FontAwesomeIcon icon={faEnvelope} /> &nbsp; mdjahidhassan2424@gmail.com
                                    </p>

                                    <p className='tooltip bg-black w-fit p-1 font-semibold text-white absolute top-[-30px] [opacity:.7] left-20'>Click to copy</p>

                                </div>

                                <p className='text-2xl font-bold mt-[10%] '> Address</p>
                                <p className='text-md  mt-[5%] leading-loose'> Halifax,NS, Canada <br /> </p>

                                <div className='flex gap-8 mt-8'>
                                    <a href='https://www.linkedin.com/in/jahidhassan2424/' rel="noreferrer" target={"_blank"}>
                                        <FontAwesomeIcon className='text-2xl' icon={faLinkedinIn} />
                                    </a>

                                    <a href='https://github.com/jahidhassan2424' rel="noreferrer" target={"_blank"}>
                                        <FontAwesomeIcon className='text-2xl' icon={faGithub} />
                                    </a>

                                </div>
                            </div>
                        </div>
                        <div className='h-full xs:pb-[10%] '  >
                            <div className=' flex justify-end justify-items-end  h-full '>
                                <form onSubmit={handleSubmit(onSubmit)} className="relative flex justify-end ">
                                    <div class="w-full  shadow-xl p-5 rounded-xl h-full bg-zinc-900 ">
                                        <input type="text" placeholder='Your Name' className='border-b-2  bg-zinc-900 text-white  border-gray-500 rounded-xl  p-3 w-full mb-10'
                                            {...register('name')} required
                                        />

                                        <input type="email" placeholder='Your Email' className='border-b-2 bg-zinc-900 text-white   border-gray-500 rounded-xl  p-3 w-full mb-10'
                                            {...register('email')} required
                                        />

                                        <textarea type="text" placeholder='Message' className='border-b-2 bg-zinc-900 text-white   border-gray-500 rounded-xl  p-3 w-full mb-10'
                                            {...register('message')} required
                                        />

                                        <div className='text-center flex items-center justify-center justify-items-center w-full'>
                                            <button type='submit' className='bg-white rounded-xl w-full text-black py-1 font-bold text-5xl  '>{loadingEmail ? <span className='text-2xl flex flex-row justify-center'>
                                                <ReactLoading type={'spin'} color={'#000'} height={30} width={30} /></span> : "Send"}</button>

                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ContactMe;